<template>
  <div>
    <div class="d-flex mb-2">
      <b-button variant="primary" size="lg" @click="openModalAdding">
        <feather-icon class="ml-0" icon="PlusIcon" size="20" /> Додати статус
      </b-button>
    </div>
    <h1>
      Доступні статуси
    </h1>
    <p class="mb-2">
      Статуси відображають поточний стан заявки впродовж її життєвого циклу від
      створення то вирішення
    </p>

    <b-overlay spinner-variant="primary" spinner-size="lg" :show="isLoading">
      <b-card v-for="s in statuses" :key="s.id">
        <h4 class="d-flex align-items-center mb-0">
          <statusBadge :statusObj="s" />
          <b-badge variant="light-primary" class="ml-2">ID:{{ s.id }}</b-badge>
          <div class="controls ml-auto">
            <b-badge
              variant="light-success"
              class="d-flex align-items-center"
              v-if="s.protected"
            >
              <feather-icon icon="ShieldIcon" />
              &nbsp; Захищений
            </b-badge>
            <b-button
              @click="openModalEditing(s)"
              variant="outline-primary"
              v-else
              size="sm"
            >
              <feather-icon icon="EditIcon" /> Редагувати
            </b-button>
          </div>
        </h4>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="addPupUpShow"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <b-overlay spinner-variant="primary" :show="modalLoading">
        <h3 class="mb-2" v-if="modalCase == 'add'">
          Додавання нового статусу
        </h3>
        <h3 class="mb-2" v-if="modalCase == 'edit'">
          Редагування статусу
        </h3>
        <div class="form-input mb-2">
          <label for="">
            Видима назва статусу
          </label>
          <b-form-input v-model.trim="new_status.name"></b-form-input>
        </div>
        <div class="form-input mb-1">
          <label for="">Колір мітки</label>
          <div class="d-flex mt-2">
            <span
              class="color-swatch mr-1 mb-1"
              v-for="c in colors"
              :key="c"
              :style="{ backgroundColor: c }"
              :class="{ selected: c == new_status.color }"
              @click="setColor(c)"
            >
              <feather-icon icon="CheckIcon" />
            </span>
          </div>
        </div>
        <template v-if="modalCase == 'edit'">
          <b-alert show variant="danger" class="px-1 py-1">
            <strong> Увага!</strong> Нові назва і колір статусу також будуть
            відображатись у всіх заявках, які мають цей статус на даний момент
          </b-alert>
        </template>
        <div class="mt-2">
          <b-button
            :disabled="addButtonDisabled || statusExists"
            class="mb-1"
            variant="success"
            size="lg"
            block
            @click="saveStatus"
          >
            Зберегти статус
          </b-button>
          <b-button
            variant="outline-danger"
            size="lg"
            block
            @click="cancelAdding"
          >
            Скасувати
          </b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import statusBadge from "@/components/ticket-status-badge";
export default {
  components: {
    statusBadge,
  },
  data() {
    return {
      modalCase: "add",
      modalLoading: false,
      isLoading: false,
      statuses: [],
      addPupUpShow: false,
      new_status: {
        color: "#000",
        id: null,
        name: "",
      },
      edited_status: {},
      colors: [
        "#000",
        "#f00",
        "#f60",
        "#108fe5",
        "#ff4b1f",
        "#5D26C1",
        "#89216B",
      ],
    };
  },
  computed: {
    statusExists() {
      var exists = false;
      var found = this.statuses.find((e) => e.name == this.new_status.name);
      if (found) {
        exists = true;
      }
      return exists;
    },
    addButtonDisabled() {
      var disabled = false;
      if (this.new_status.name.length < 3 || this.new_status.name > 12) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    async getStatuses() {
      this.isLoading = true;
      this.statuses = [];
      var result = await this.$db.getCollection({ collectionName: "statuses" });
      this.statuses = result.docs;
      this.isLoading = false;
    },
    openModalEditing(s) {
      this.modalCase = "edit";
      this.addPupUpShow = true;
      Object.assign(this.new_status, s);
    },
    openModalAdding() {
      this.modalCase = "add";
      this.addPupUpShow = true;
    },
    cancelAdding() {
      this.addPupUpShow = false;
      Object.assign(this.new_status, {
        name: "",
        color: "#000",
        id: null,
      });
    },
    saveStatus() {
      if (this.modalCase == "add") {
        this.createStatus();
      }
      if (this.modalCase == "edit") {
        this.updateStatus();
      }
    },
    async updateStatus() {
      this.modalLoading = true;
      var uid = this.new_status.uid;
      var data = this.new_status;
      delete data["uid"];
      await this.$db.update("statuses", uid, data);
      this.$toast.success("Статус оновлено");
      setTimeout(() => {
        this.$toast.success("Оновлення даних статусів");
      }, 3000);
      setTimeout(() => {
        this.cancelAdding();
        this.$store.dispatch("getDatasets");
        this.getStatuses();
        this.modalLoading = false;
      }, 5000);
    },
    async createStatus() {
      this.modalLoading = true;
      var id = await this.$api
        .get("/get/counter/?type=statuses")
        .then((response) => {
          return response.data.count;
        })
        .catch((error) => {
          alert(error);
        });
      if (id) {
        this.new_status.id = id;
        var result = await this.$db.add("statuses", this.new_status);
        if (result.success) {
          this.$toast.success("Статус створено");
          setTimeout(() => {
            this.cancelAdding();
            this.$store.dispatch("getDatasets");
            this.getStatuses();
            this.modalLoading = false;
          }, 5000);
        }
      }
    },
    setColor(color) {
      this.new_status.color = color;
    },
  },
  mounted() {
    this.getStatuses();
  },
};
</script>

<style lang="scss" scoped>
.color-swatch {
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 12px 0px rgba(#000, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
  svg {
    opacity: 0;
    transform: scale(0);
    transition: 0.3s;
  }
  &.selected {
    box-shadow: 0px 0px 12px 0px rgba(#000, 0.2);
    svg {
      opacity: 1;
      transform: scale(1.2);
      transition: 0.3s;
    }
  }
}
</style>
